<template>
  <div id="printable">
    <v-table
      :row-active-id="rowActiveId"
      :data="tableData"
      :columns="columns"
      clickable
      @row-click="onRowClick"
      @row-dblclick="onRowDblClick"
    >
      <template v-slot:column-time="item">
        <p
          v-if="item.row.isNew"
          :class="$style.new"
        >
          NEW
        </p>
        {{ item.row.date | dateTime }}
      </template>
      <template v-slot:column-action="item">
        <span :class="$style.dangerous">
          <dangerous :value="item.row.statusDangerous" />
        </span>
        <span>{{ item.row.action | action }}</span>
      </template>
      <template v-slot:column-source="item">
        {{ item.row.source | source }}
      </template>
      <template v-slot:column-filename="item">
        <contextmenu :buttons="getContextmenuButtons(item.row.id)">
          {{ item.row.filename }}
        </contextmenu>
      </template>
    </v-table>
    <additional-list
      v-if="rowActiveId"
      :list="additionalList"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import VTable from '@/components/common/table/table.vue';
import { dateTime } from '@/helpers/filters';
import Dangerous from '@/components/common/dangerous/index.vue';
import AdditionalList from '@/components/common/logs/additional/list/index.vue';
import { getShadowCopyingFile } from '@/api/methods/computers';
import { LogData } from '@/domains/log/data';
import Contextmenu from '@/components/common/contextmenu/index.vue';
import { source, action, available } from './utils';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

export default {
  components: {
    VTable,
    Dangerous,
    AdditionalList,
    Contextmenu,
  },
  filters: {
    dateTime,
    source,
    action,
  },
  props: {
    data: {
      type: null,
      required: true,
    },
  },
  data: () => ({
    columns: [
      {
        field: 'time',
        title: 'Время',
        sortFn: (isAsc) => (a, b) => (a.date - b.date) * (isAsc ? 1 : -1),
      },
      { field: 'action', title: 'Действие' },
      { field: 'filename', title: 'Имя файла' },
      { field: 'filesize', title: 'Размер файла, кб' },
      { field: 'source', title: 'Тип' },
      { field: 'username', title: 'Имя пользователя' },
    ],
  }),
  computed: {
    rowActiveId() {
      return parseInt(this.$route.params.record, 10);
    },
    tableData() {
      return LogData.filterTable(
        this.data,
        [
          (item) => dateTime(item.date),
          'filename',
          (item) => source(item.source),
          'username',
        ],
        this.$route.query.filter,
      );
    },
    activeRow() {
      const activeRow = this.data.find((i) => i.id === this.rowActiveId);

      return activeRow || {};
    },
    additionalList() {
      const row = this.activeRow;

      return [
        ['Время', dateTime(row.date)],
        ['Действие', action(row.action)],
        ['Имя файла', row.filename],
        ['Размер файла', row.filesize],
        ['Размещение файла', available(row.available)],
        ['Тип', source(row.source)],
        ['File MD5', row.crc],
        ['Имя пользователя', row.username],
      ];
    },
  },
  methods: {
    onRowClick(id) {
      this.$router.push({
        name: 'computers-computer-log-record',
        params: { record: id },
        query: this.$route.query,
      });
    },
    onRowDblClick() {
      this.downloadFile(parseInt(this.$route.params.record, 10));
    },
    async downloadFile(recordId) {
      const result = await getShadowCopyingFile(
        parseInt(this.$route.params.computer, 10),
        recordId,
      );

      if (!result.success) {
        Vue.$toast.open({
          message: result.message,
          type: 'error',
        });
      }
    },
    getContextmenuButtons(id) {
      return [
        {
          label: 'Сохранить файл',
          onClick: () => this.downloadFile(id),
        },
      ];
    },
  },
};
</script>

<style lang="scss" module>
.new {
  display: inline-block;
  background: $light-blue;
  color: $blue;
  font-size: 7px;
  padding: 3px;
  margin-right: 8px;
  border-radius: 1px;
}

.dangerous {
  margin-right: 8px;
}

.tableColumnText {
  word-break: break-all;
}</style>
