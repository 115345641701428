<template>
  <div id="printable">
    <div v-if="isDataLoading">
      Загрузка...
    </div>
    <v-table
      v-else
      :row-active-id="rowActiveId"
      :data="tableData"
      :columns="columns"
      clickable
      @row-click="onRowClick"
    >
      <template v-slot:column-time="item">
        <p
          v-if="item.row.isNew"
          :class="$style.new"
        >
          NEW
        </p>
        {{ item.row.date | dateTime }}
      </template>
      <template v-slot:column-interlocutor="item">
        <span :class="$style.dangerous">
          <dangerous :value="item.row.statusDangerous" />
        </span>
        <span v-if="item.row.customNickname">
          {{ item.row.customNickname }} ({{ item.row.interlocutor }})
        </span>
        <span v-else>
          {{ item.row.interlocutor }}
        </span>
      </template>
      <template v-slot:column-messenger="item">
        <img
          :src="getMessengerIcon(item.row.messenger)"
          :class="$style.messengerIcon"
        >
        <span :class="$style.messengerName">{{
          item.row.messenger | messengerName
        }}</span>
      </template>
      <template v-slot:column-direction="item">
        <img
          :src="getMessageDirectionIcon(item.row.direction)"
          :class="$style.directionIcon"
        >
        <span :class="$style.directionName">{{
          item.row.direction | directionName
        }}</span>
      </template>
      <template v-slot:column-text="item">
        <column-text
          :row="item.row"
          :record-id="item.row.id"
        />
      </template>
    </v-table>
    <additional
      v-if="rowActiveId"
      :row="activeRow"
      :link="computerActiveId"
      @image-dblclick="openModal"
    />
    <modal
      v-if="isShowModal"
      :title="activeRow.caption"
      :fullscreen="true"
      @close="close"
    >
      <div :class="$style.content">
        <div :class="$style.imageWrapper">
          <img :src="activeLink">
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import {
  getMessengersAttachment,
  getMessengersAttachmentLink,
} from '@/api/methods/computers';
import workerBasedRequestMixin from '@/components/common/logs/mixins/workerBasedRequestMixin';
import IconMessengerSkype from '@/assets/images/icons/messengers/skype-color.svg';
import IconMessengerViber from '@/assets/images/icons/messengers/viber-color.svg';
import IconMessengerWhatsApp from '@/assets/images/icons/messengers/whatsapp-color.svg';
import IconMessengerTelegram from '@/assets/images/icons/messengers/telegram-color.svg';
import IconMessageDirectionInbox from '@/assets/images/icons/message-direction/inbox.svg';
import IconMessageDirectionOutbox from '@/assets/images/icons/message-direction/outbox.svg';
import VTable from '@/components/common/table/table.vue';
import Dangerous from '@/components/common/dangerous/index.vue';
import { dateTime } from '@/helpers/filters';
import { LogData } from '@/domains/log/data';
import Modal from '@/components/common/modal/index.vue';
import Additional from './components-default/additional.vue';
import { messengerName, directionName } from './filters';
import ColumnText from './components-default/column-text.vue';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

export default {
  components: {
    VTable,
    Dangerous,
    Additional,
    ColumnText,
    Modal,
  },
  filters: {
    dateTime,
    messengerName,
    directionName,
  },
  mixins: [workerBasedRequestMixin],
  data: () => ({
    tableData: [],
    columns: [
      {
        field: 'time',
        title: 'Время',
        sortFn: (isAsc) => (a, b) => (new Date(a.date) - new Date(b.date)) * (isAsc ? 1 : -1),
      },
      { field: 'messenger', title: 'Мессенджер' },
      { field: 'interlocutor', title: 'Собеседник' },
      { field: 'direction', title: 'Тип сообщения' },
      { field: 'text', title: 'Текст сообщения/файл' },
      { field: 'username', title: 'Имя пользователя' },
    ],
  }),
  computed: {
    isShowModal() {
      return Object.prototype.hasOwnProperty.call(this.$route.query, 'modal');
    },
    rowActiveId() {
      return this.$route.params.record;
    },
    computerActiveId() {
      return this.$route.params.computer;
    },
    activeRow() {
      const activeRow = this.pagedData.find((i) => i.id === this.rowActiveId);

      return activeRow || {};
    },
    activeLink() {
      return getMessengersAttachmentLink(
        parseInt(this.$route.params.computer, 10),
        this.rowActiveId,
      );
    },
  },
  watch: {
    '$route.query': function (query, oldQuery) {
      // eslint-disable-line func-names
      if (
        query.dateFrom !== oldQuery.dateFrom
        || query.dateTo !== oldQuery.dateTo
      ) {
        this.fetch();
      }
      if (query.filter !== oldQuery.filter) {
        this.getTableData();
      }
    },
    pagedData() {
      this.getTableData();
    },
  },
  async created() {
    await this.fetch();
  },
  methods: {
    getTableData() {
      let items = [...this.pagedData];
      items = items.filter((element) => element !== undefined);

      if (items.length > 0) {
        this.tableData = LogData.filterTable(
          items,
          [
            (item) => dateTime(item.date),
            (item) => directionName(item.direction),
            'username',
            'interlocutor',
            'customNickname',
            (item) => item.attachment && item.attachment.filename,
            'usernameMessenger',
          ],
          this.$route.query.filter,
        );
      }
    },
    openModal() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          modal: null,
        },
      });
    },
    close() {
      const query = { ...this.$route.query };
      delete query.modal;

      this.$router.replace({
        query,
      });
    },
    onRowClick(id) {
      this.$router.push({
        name: 'computers-computer-log-record',
        params: { record: id },
        query: this.$route.query,
      });
    },
    getMessengerIcon(id) {
      switch (id) {
        case 0:
          return IconMessengerSkype;
        case 1:
          return IconMessengerViber;
        case 2:
          return IconMessengerWhatsApp;
        case 3:
          return IconMessengerTelegram;
        default:
          return null;
      }
    },
    getMessageDirectionIcon(direction) {
      switch (direction) {
        case 31:
          return IconMessageDirectionInbox;
        case 32:
          return IconMessageDirectionOutbox;
        default:
          return null;
      }
    },
    async generateAttachmentLink(recordId) {
      const result = await getMessengersAttachment(
        parseInt(this.$route.params.computer, 10),
        recordId,
      );
      if (!result.success) {
        Vue.$toast.open({
          message: result.message,
          type: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" module>
.new {
  display: inline-block;
  background: $light-blue;
  color: $blue;
  font-size: 7px;
  padding: 3px;
  margin-right: 8px;
  border-radius: 1px;
}

.dangerous {
  display: inline-block;
  margin-right: 8px;
}

.messengerIcon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}

.messengerName {
  vertical-align: middle;
}

.directionIcon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}

.directionName {
  vertical-align: middle;
}

.content {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageWrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 30px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}
</style>
