<template>
  <modal @close="close">
    <v-input v-model="nickname" />
    <template v-slot:footer>
      <button @click="update">
        Применить {{ nickname }}
      </button>
      <button @click="close">
        Отмена
      </button>
    </template>
  </modal>
</template>

<script>
import VInput from '@/components/common/form/input/index.vue';
import Modal from '@/components/common/modal/index.vue';

export default {
  components: {
    Modal,
    VInput,
  },
  props: {
    currentNickname: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      nickname: this.currentNickname,
    };
  },
  methods: {
    update() {
      this.$emit('update');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
