<template>
  <div id="printable">
    <div v-if="isDataLoading">
      Загрузка...
    </div>
    <v-table v-else :row-active-id="rowActiveId" :data="tableData" :columns="columns" clickable @row-click="onRowClick">
      <template v-slot:column-time="item">
        <p v-if="item.row.isNew" :class="$style.new">
          NEW
        </p>
        {{ item.row.date | dateTime }}
      </template>
      <template v-slot:column-action="item">
        <span :class="$style.dangerous">
          <dangerous :value="item.row.statusDangerous" />
        </span>
        {{ item.row.action | action }}
      </template>
    </v-table>
    <additional-list v-if="rowActiveId" :list="additionalList" />
  </div>
</template>

<script>
import VTable from '@/components/common/table/table.vue';
import { dateTime } from '@/helpers/filters';
import Dangerous from '@/components/common/dangerous/index.vue';
import AdditionalList from '@/components/common/logs/additional/list/index.vue';

import { LogData } from '@/domains/log/data';
import workerBasedRequestMixin from '@/components/common/logs/mixins/workerBasedRequestMixin';
import { action } from './utils';

export default {
  components: {
    VTable,
    Dangerous,
    AdditionalList,
  },
  filters: {
    dateTime,
    action,
  },
  mixins: [workerBasedRequestMixin],
  data: () => ({
    tableData: [],
    columns: [
      {
        field: 'time',
        title: 'Время',
        sortFn: (isAsc) => (a, b) => (new Date(a.date) - new Date(b.date)) * (isAsc ? 1 : -1),
      },
      { field: 'action', title: 'Действие' },
      { field: 'path', title: 'Путь' },
      { field: 'crc', title: 'MD5' },
      { field: 'size', title: 'Size' },
      { field: 'username', title: 'Имя пользователя' },
    ],
  }),
  computed: {
    rowActiveId() {
      return parseInt(this.$route.params.record, 10);
    },
    activeRow() {
      const activeRow = this.pagedData.find((i) => i.id === this.rowActiveId);

      return activeRow || {};
    },
    additionalList() {
      const row = this.activeRow;

      return [
        ['Время', dateTime(row.date)],
        ['Действие', action(row.action)],
        ['Путь', row.path],
        ['File MD5', row.crc],
        ['Size', row.size],
        ['Имя пользователя', row.username],
      ];
    },
  },
  watch: {
    '$route.query': function (query, oldQuery) {
      // eslint-disable-line func-names
      if (
        query.dateFrom !== oldQuery.dateFrom
        || query.dateTo !== oldQuery.dateTo
      ) {
        this.fetch();
      }
      if (query.filter !== oldQuery.filter) {
        this.getTableData();
      }
    },
    pagedData() {
      this.getTableData();
    },
  },
  async created() {
    await this.fetch();
  },
  methods: {
    getTableData() {
      let items = [...this.pagedData];
      items = items.filter((element) => element !== undefined);

      if (items.length > 0) {
        this.tableData = LogData.filterTable(
          items,
          [
            (item) => dateTime(item.date),
            (item) => action(item.action),
            'path',
            'crc',
            'username',
          ],
          this.$route.query.filter,
        );
      }
    },
    onRowClick(id) {
      this.$router.push({
        name: 'computers-computer-log-record',
        params: { record: id },
        query: this.$route.query,
      });
    },
  },
};
</script>

<style lang="scss" module>
.new {
  display: inline-block;
  background: $light-blue;
  color: $blue;
  font-size: 7px;
  padding: 3px;
  margin-right: 8px;
  border-radius: 1px;
}

.dangerous {
  display: inline-block;
  margin-right: 8px;
}
</style>
