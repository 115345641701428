<template>
  <div id="printable">
    <div v-if="isDataLoading">
      Загрузка...
    </div>
    <v-table
      v-if="$route.query.view === 'table' && !isDataLoading"
      :row-active-id="rowActiveId"
      :data="tableData"
      :columns="columns"
      clickable
      @row-click="onRowClick"
      @row-dblclick="openModal"
    >
      <template v-slot:column-time="item">
        <p
          v-if="item.row.isNew"
          :class="$style.new"
        >
          NEW
        </p>
        {{ item.row.date | dateTime }}
      </template>
      <template v-slot:column-caption="item">
        <span :class="$style.dangerous">
          <dangerous :value="item.row.statusDangerous" />
        </span>
        {{ item.row.caption }}
      </template>
    </v-table>
    <view-grid
      v-if="$route.query.view === 'grid' && !isDataLoading"
      :screenshots="tableData"
    />
    <additional-list-image
      v-if="rowActiveId"
      :list="additionalList"
      :image="additionalImage"
      @image-dblclick="openModal"
    />
    <screenshots-modal
      v-if="isShowModal"
      :screenshots="tableData"
    />
  </div>
</template>

<script>
import VTable from '@/components/common/table/table.vue';
import AdditionalListImage from '@/components/common/logs/additional/list-image/index.vue';
import ScreenshotsModal from '@/components/pages/computers/log/screenshots/components-default/modal.vue';
import ViewGrid from '@/components/pages/computers/log/screenshots/components-default/view-grid.vue';
import { dateTime } from '@/helpers/filters';
import Dangerous from '@/components/common/dangerous/index.vue';
import { getScreenshotUrl } from '@/helpers/methods';
import { LogData } from '@/domains/log/data';

import workerBasedRequestMixin from '@/components/common/logs/mixins/workerBasedRequestMixin';

export default {
  components: {
    VTable,
    AdditionalListImage,
    ScreenshotsModal,
    ViewGrid,
    Dangerous,
  },
  filters: {
    dateTime,
  },
  mixins: [workerBasedRequestMixin],
  data: () => ({
    tableData: [],
    columns: [
      {
        field: 'time',
        title: 'Время',
        sortFn: (isAsc) => (a, b) => (new Date(a.date) - new Date(b.date)) * (isAsc ? 1 : -1),
      },
      { field: 'caption', title: 'Заголовок окна' },
      { field: 'username', title: 'Имя пользователя' },
    ],
  }),
  computed: {
    rowActiveId() {
      return parseInt(this.$route.params.record, 10);
    },
    isShowModal() {
      return Object.prototype.hasOwnProperty.call(this.$route.query, 'modal');
    },
    activeRow() {
      const activeRow = this.pagedData.find((i) => i.id === this.rowActiveId);

      return activeRow || {};
    },
    additionalList() {
      const row = this.activeRow;

      return [
        ['Время', dateTime(row.date)],
        ['Заголовок окна', row.caption],
        ['Путь', row.path],
      ];
    },
    additionalImage() {
      const row = this.activeRow;
      return getScreenshotUrl.call(this, row.path);
    },
  },
  watch: {
    '$route.query': function (query, oldQuery) {
      // eslint-disable-line func-names
      if (
        query.dateFrom !== oldQuery.dateFrom
        || query.dateTo !== oldQuery.dateTo
      ) {
        this.fetch();
      }
      if (query.filter !== oldQuery.filter) {
        this.getTableData();
      }
    },
    pagedData() {
      this.getTableData();
    },
  },
  async created() {
    await this.fetch();
    if (!this.$route.query.view) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          view: 'table',
        },
      });
    }
  },
  methods: {
    getTableData() {
      let items = [...this.pagedData];
      items = items.filter((element) => element !== undefined);

      if (items.length > 0) {
        this.tableData = LogData.filterTable(
          items,
          [(item) => dateTime(item.date), 'caption', 'username'],
          this.$route.query.filter,
        );
      }
    },
    onRowClick(id) {
      this.$router.push({
        name: 'computers-computer-log-record',
        params: { record: id },
        query: this.$route.query,
      });
    },
    openModal() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          modal: null,
        },
      });
    },
  },
};
</script>

<style lang="scss" module>
.new {
  display: inline-block;
  background: $light-blue;
  color: $blue;
  font-size: 7px;
  padding: 3px;
  margin-right: 8px;
  border-radius: 1px;
}

.dangerous {
  display: inline-block;
  margin-right: 8px;
}
</style>
