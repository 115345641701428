<template>
  <div id="printable">
    <v-table
      :row-active-id="rowActiveId"
      :data="tableData"
      :columns="columns"
      clickable
      @row-dblclick="onRowDblClick"
      @row-click="onRowClick"
    >
      <template v-slot:column-time="item">
        <p v-if="item.row.isNew" :class="$style.new">
          NEW
        </p>
        {{ item.row.date | dateTime }}
      </template>

      <template v-slot:column-description="item">
        <contextmenu :buttons="getContextmenuButtons(item.row.id)">
          <div v-if="item.row.description">
            {{ item.row.description }}
          </div>
          <div v-if="!item.row.description && item.row.exe">
            {{ item.row.exe }}
          </div>
          {{ item.row.filename }}
        </contextmenu>
      </template>

      <template v-slot:column-duration="item">
        {{ item.row.duration | duration }}
      </template>
    </v-table>
    <additional-list v-if="rowActiveId" :list="additionalList" />
  </div>
</template>

<script>
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import VTable from '@/components/common/table/table.vue';
import { dateTime, duration } from '@/helpers/filters';
import { LogData } from '@/domains/log/data';
import { getMicRecordFile } from '@/api/methods/computers';
import AdditionalList from '@/components/common/logs/additional/list/index.vue';
import Contextmenu from '@/components/common/contextmenu/index.vue';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

export default {
  components: {
    VTable,
    AdditionalList,
    Contextmenu,
  },
  filters: {
    dateTime,
    duration,
  },
  props: {
    data: {
      type: null,
      required: false,
    },
  },
  data: () => ({
    columns: [
      {
        field: 'time',
        title: 'Время',
        sortFn: (isAsc) => (a, b) => (a.date - b.date) * (isAsc ? 1 : -1),
      },
      { field: 'description', title: 'Программа' },
      { field: 'caption', title: 'Заголовок окна' },
      { field: 'duration', title: 'Длительность' },
      { field: 'username', title: 'Имя пользователя' },
    ],
  }),
  computed: {
    rowActiveId() {
      return parseInt(this.$route.params.record, 10);
    },
    tableData() {
      return LogData.filterTable(
        this.data,
        [(item) => dateTime(item.date), 'description', 'caption', 'username'],
        this.$route.query.filter,
      );
    },
    activeRow() {
      const activeRow = this.data.find((i) => i.id === this.rowActiveId);

      return activeRow || {};
    },
    additionalList() {
      const row = this.activeRow;

      let program = '';
      if (row.description) program = row.description;
      else if (row.exe) program = row.exe;

      return [
        ['Время', dateTime(row.date)],
        ['Программа', program],
        ['Путь к программе', row.procPath],
        ['Заголовок окна', row.caption],
        ['Длительность, сек', duration(row.duration)],
        ['Путь', row.filePath],
        ['Имя пользователя', row.username],
      ];
    },
  },
  methods: {
    async saveFile(id) {
      const result = await getMicRecordFile(
        parseInt(this.$route.params.computer, 10),
        parseInt(id, 10),
      );
      if (!result.success) {
        Vue.$toast.open({
          message: result.message,
          type: 'error',
        });
      }
    },
    getContextmenuButtons(id) {
      return [
        {
          label: 'Сохранить файл',
          onClick: () => this.saveFile(id),
        },
      ];
    },
    onRowDblClick() {
      this.saveFile(parseInt(this.$route.params.record, 10));
    },
    onRowClick(id) {
      this.$router.push({
        name: 'computers-computer-log-record',
        params: { record: id },
        query: this.$route.query,
      });
    },
  },
};
</script>

<style lang="scss" module>
.new {
  display: inline-block;
  background: $light-blue;
  color: $blue;
  font-size: 7px;
  padding: 3px;
  margin-right: 8px;
  border-radius: 1px;
}
</style>
