var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{class:_vm.$style.table},[_c('thead',[_c('tr',_vm._l((_vm.columns),function(column){
var _obj;
return _c('th',{key:column.field,on:{"click":function($event){return _vm.onColumnClick(column.field)}}},[_vm._v(" "+_vm._s(column.title)+" "),(_vm.sortedColumn === column.field)?_c('span',{class:[_vm.$style.sort, ( _obj = {}, _obj[_vm.$style.desc] = !_vm.isSortAscending, _obj )]},[_vm._v(" ▾ ")]):_vm._e()])}),0)]),(_vm.tableData.length)?_c('tbody',{class:( _obj = {}, _obj[_vm.$style.clickable] = _vm.clickable, _obj )},_vm._l((_vm.tableData),function(row,idx){
var _obj;
return _c('tr',{key:row.id,class:[
        ( _obj = {}, _obj[_vm.$style.active] = row.id === _vm.rowActiveId, _obj ),
        _vm.getRowParameterClasses(row.id) ],attrs:{"tabindex":idx + 1},on:{"click":function($event){return _vm.onRowClick(row.id)},"dblclick":function($event){return _vm.onRowDblClick(row.id)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"arrow-up",undefined,$event.key,undefined)){ return null; }$event.preventDefault();return _vm.focusRow(idx - 1)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"arrow-down",undefined,$event.key,undefined)){ return null; }$event.preventDefault();return _vm.focusRow(idx + 1)}],"focus":function($event){return _vm.onRowClick(row.id)}}},_vm._l((_vm.columns),function(column,index){
      var _obj;
return _c('td',{key:column.field,class:( _obj = {}, _obj[_vm.$style.firstColumn] = index === 0, _obj[_vm.$style.lastColumn] = index === _vm.columns.length - 1, _obj )},[(_vm.hasDataColumnSlot(column.field))?_vm._t(("column-" + (column.field)),null,{"row":row}):[_vm._v(" "+_vm._s(row[column.field])),void 0]],2)}),0)}),0):_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":_vm.columns.length}},[_vm._v(" Данные отсутствуют ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }