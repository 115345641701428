<template>
  <base-collapse>
    <div :class="$style.content">
      <div :class="$style.audio">
        <audio
          :src="audio.url"
          :type="audio.contentType"
        >Ваш браузер не поддерживает воспроизведение аудиофайлов</audio>
      </div>
      <partial-list :list="list" />
    </div>
  </base-collapse>
</template>

<script>
// TODO: Дублирование кода из list-image
import BaseCollapse from '../_base/collapse.vue';
import PartialList from '../_partials/list.vue';

export default {
  components: {
    BaseCollapse,
    PartialList,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    audio: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" module>
.content {
  display: grid;
  grid-template-columns: 3fr 5fr;
}

.audio {
  background: $light-blue;
  padding: 20px;
}
</style>
