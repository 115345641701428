<template>
  <promised :promise="promise" tag="div">
    <template v-slot="data">
      <component :is="component" :data="data" />
    </template>
    <template v-slot:pending>
      Загрузка...
    </template>
    <template v-slot:rejected="error">
      Ошибка: {{ printError(error) }}
    </template>
  </promised>
</template>

<script>
import dayjs from 'dayjs';
import Screenshots from '@/components/pages/computers/log/screenshots/view-default.vue';
import Keyboard from '@/components/pages/computers/log/keyboard/view-default.vue';
import Programs from '@/components/pages/computers/log/programs/view-default.vue';
import VisitedSites from '@/components/pages/computers/log/visited-sites/view-default.vue';
import Clipboard from '@/components/pages/computers/log/clipboard/view-default.vue';
import InstalledPrograms from '@/components/pages/computers/log/installed-programs/view-default.vue';
import Files from '@/components/pages/computers/log/files/view-default.vue';
import Printer from '@/components/pages/computers/log/printer/view-default.vue';
import ExternalDrives from '@/components/pages/computers/log/external-drives/view-default.vue';
import SocialNetworks from '@/components/pages/computers/log/social-networks/view-default.vue';
import SearchQueries from '@/components/pages/computers/log/search-queries/view-default.vue';
import FileUpload from '@/components/pages/computers/log/file-upload/view-default.vue';
import WebMail from '@/components/pages/computers/log/web-mail/view-default.vue';
import Messengers from '@/components/pages/computers/log/messengers/view-default.vue';
import Computer from '@/components/pages/computers/log/computer/view-default.vue';
import EmailClients from '@/components/pages/computers/log/email-clients/view-default.vue';
import ShadowCopyings from '@/components/pages/computers/log/shadow-copyings/view-default.vue';
import NetworkAccesses from '@/components/pages/computers/log/network-accesses/view-default.vue';
import NetworkFiles from '@/components/pages/computers/log/network-files/view-default.vue';
import HardwareChanges from '@/components/pages/computers/log/hardware-changes/view-default.vue';
import WebcamsAndMicrophones from '@/components/pages/computers/log/webcams-and-microphones/view-default.vue';
import MicRecords from '@/components/pages/computers/log/mic-records/view-default.vue';

import { getLogs } from '@/api/methods/computers';

export default {
  data: () => ({
    promise: null,
  }),
  computed: {
    component() {
      switch (this.$route.params.log) {
        case 'screenshots':
          return Screenshots;
        case 'keyboard':
          return Keyboard;
        case 'programs':
          return Programs;
        case 'visited-sites':
          return VisitedSites;
        case 'clipboard':
          return Clipboard;
        case 'installed-programs':
          return InstalledPrograms;
        case 'files':
          return Files;
        case 'printer':
          return Printer;
        case 'external-drives':
          return ExternalDrives;
        case 'social-networks':
          return SocialNetworks;
        case 'search-queries':
          return SearchQueries;
        case 'file-upload':
          return FileUpload;
        case 'web-mail':
          return WebMail;
        case 'messengers':
          return Messengers;
        case 'computer':
          return Computer;
        case 'email-clients':
          return EmailClients;
        case 'shadow-copyings':
          return ShadowCopyings;
        case 'network-accesses':
          return NetworkAccesses;
        case 'network-files':
          return NetworkFiles;
        case 'hardware-changes':
          return HardwareChanges;
        case 'webcams-and-microphones':
          return WebcamsAndMicrophones;
        case 'mic-records':
          return MicRecords;
        default:
          throw Error(`Unknown default log ${this.route.params.log}`);
      }
    },
  },
  watch: {
    '$route.query': function (query, oldQuery) {
      // eslint-disable-line func-names
      if (
        query.dateFrom !== oldQuery.dateFrom
        || query.dateTo !== oldQuery.dateTo
      ) {
        this.fetch();
      }
    },
    '$route.params.log': function () {
      // eslint-disable-line func-names
      this.fetch();
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      const logTypeSlug = this.$route.params.log;
      const computerId = parseInt(this.$route.params.computer, 10);

      const dateFrom = dayjs(this.$route.query.dateFrom)
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0);

      const dateTo = dayjs(this.$route.query.dateTo)
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59)
        .set('millisecond', 0);

      if (logTypeSlug !== 'email-clients' && logTypeSlug !== 'keyboard'
        && logTypeSlug !== 'clipboard' && logTypeSlug !== 'messengers'
        && logTypeSlug !== 'screenshots' && logTypeSlug !== 'files') {
        this.promise = getLogs(
          computerId,
          logTypeSlug,
          this.$store.getters['historyLogs/getLastId'](logTypeSlug, computerId),
          dateFrom,
          dateTo,
        ).then((data) => {
          this.$store.dispatch('historyLogs/setLastId', {
            id: data.reduce((acc, item) => (item.id > acc ? item.id : acc), 0),
            logTypeSlug,
            idComputer: computerId,
          });

          // this.$store.commit('printScreenshots/screenshots', data);

          return data;
        });
      } else this.promise = new Promise((resolve) => { resolve(''); });
    },
    printError(error) {
      return error.response && error.response.data
        ? error.response.data.message
        : error.message;
    },
  },
};
</script>
