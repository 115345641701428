<template>
  <div id="printable">
    <v-table
      :row-active-id="rowActiveId"
      :data="tableData"
      :columns="columns"
      clickable
      @row-click="onRowClick"
    >
      <template v-slot:column-time="item">
        <p v-if="item.row.isNew" :class="$style.new">
          NEW
        </p>
        {{ item.row.date | dateTime }}
      </template>
      <template v-slot:column-action="item">
        {{ item.row.action | action }}
      </template>
    </v-table>
    <additional-list v-if="rowActiveId" :list="additionalList" />
  </div>
</template>

<script>
import VTable from '@/components/common/table/table.vue';
import { dateTime } from '@/helpers/filters';
import AdditionalList from '@/components/common/logs/additional/list/index.vue';
import { LogData } from '@/domains/log/data';
import { action } from './utils';

export default {
  components: {
    VTable,
    AdditionalList,
  },
  filters: {
    dateTime,
    action,
  },
  props: {
    data: {
      type: null,
      required: true,
    },
  },
  data: () => ({
    columns: [
      {
        field: 'time',
        title: 'Время',
        sortFn: (isAsc) => (a, b) => (a.date - b.date) * (isAsc ? 1 : -1),
      },
      { field: 'action', title: 'Действие' },
      { field: 'hardwareType', title: 'Тип устройства' },
      { field: 'description', title: 'Описание' },
    ],
  }),
  computed: {
    rowActiveId() {
      return parseInt(this.$route.params.record, 10);
    },
    tableData() {
      return LogData.filterTable(
        this.data,
        [(item) => dateTime(item.date), 'hardwareType', 'description'],
        this.$route.query.filter,
      );
    },
    activeRow() {
      const activeRow = this.data.find((i) => i.id === this.rowActiveId);

      return activeRow || {};
    },
    additionalList() {
      const row = this.activeRow;

      return [
        ['Время', dateTime(row.date)],
        ['Действие', action(row.action)],
        ['Тип устройства', row.hardwareType],
        ['Описание', row.description],
      ];
    },
  },
  methods: {
    onRowClick(id) {
      this.$router.push({
        name: 'computers-computer-log-record',
        params: { record: id },
        query: this.$route.query,
      });
    },
  },
};
</script>

<style lang="scss" module>
.new {
  display: inline-block;
  background: $light-blue;
  color: $blue;
  font-size: 7px;
  padding: 3px;
  margin-right: 8px;
  border-radius: 1px;
}

.dangerous {
  margin-right: 8px;
}
</style>
