<template>
  <button
    :class="[
      $style.button,
      {[$style.disabled]: disabled}
    ]"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.button {
  cursor: pointer;
  display: inline-block;
  padding: 10px;
  background-color: $light-blue;
  border-radius: 6px;
  border: 0;

  svg path,
  svg rect {
    fill: $blue;
  }

  &.disabled {
    cursor: not-allowed;
    background: $light-gray;

    svg path,
    svg rect {
      fill: $dark-gray;
    }
  }
}
</style>
