<template>
  <modal :title="currentItem.caption" :fullscreen="true" @close="close">
    <div :class="$style.content">
      <div :class="$style.imageWrapper">
        <img :src="currentPath">
      </div>
    </div>
    <template v-slot:footer>
      <footer :class="$style.footer">
        <div :class="$style.group">
          <div :class="$style.item">
            <button-blue-icon :disabled="isFirst" @click="first">
              <inline-svg
                :src="
                  require('@/assets/images/icons/computer/screenshots/modal/first.svg')
                "
                alt="В начало"
                title="В начало"
              />
            </button-blue-icon>
          </div>
          <div :class="$style.item">
            <button-blue-icon :disabled="isFirst" @click="prev">
              <inline-svg
                :src="
                  require('@/assets/images/icons/computer/screenshots/modal/prev.svg')
                "
                alt="Назад"
                title="Назад"
              />
            </button-blue-icon>
          </div>
        </div>
        <div :class="$style.group">
          <div :class="$style.item">
            <button-blue-icon
              :disabled="slideshowEnabled"
              @click="slideshowStart"
            >
              <inline-svg
                :src="
                  require('@/assets/images/icons/computer/screenshots/modal/play.svg')
                "
                alt="Воспроизведение"
                title="Воспроизведение"
              />
            </button-blue-icon>
          </div>
          <div :class="$style.item">
            <button-blue-icon
              :disabled="!slideshowEnabled"
              @click="slideshowStop"
            >
              <inline-svg
                :src="
                  require('@/assets/images/icons/computer/screenshots/modal/pause.svg')
                "
                alt="Остановка"
                title="Остановка"
              />
            </button-blue-icon>
          </div>
          <div :class="[$style.item, $style.wrapperSelect]">
            <select v-model="slideshow.interval" :class="$style.selectFps">
              <option :value="2000">
                1/2 кадра/сек
              </option>
              <option :value="1000">
                1 кадр/сек
              </option>
              <option :value="500">
                2 кадра/сек
              </option>
              <option :value="333">
                3 кадра/сек
              </option>
              <option :value="250">
                4 кадра/сек
              </option>
            </select>
            <inline-svg
              :src="
                require('@/assets/images/icons/computer/screenshots/modal/arrow-down.svg')
              "
              alt="Кадры в секунду"
              title="Кадры в секунду"
            />
          </div>
          <div :class="$style.item">
            <two-arrows-radio
              v-model="slideshow.forward"
              :slot-class="$style.slideshowDirection"
            >
              направление
            </two-arrows-radio>
          </div>
        </div>
        <div :class="$style.group">
          <div :class="$style.item">
            <button-blue-icon :disabled="isLast" @click="next">
              <inline-svg
                :src="
                  require('@/assets/images/icons/computer/screenshots/modal/next.svg')
                "
                alt="Вперед"
                title="Вперед"
              />
            </button-blue-icon>
          </div>
          <div :class="$style.item">
            <button-blue-icon :disabled="isLast" @click="last">
              <inline-svg
                :src="
                  require('@/assets/images/icons/computer/screenshots/modal/last.svg')
                "
                alt="В конец"
                title="В конец"
              />
            </button-blue-icon>
          </div>
        </div>
        <div :class="$style.delimeter" />
        <div :class="$style.group">
          <div :class="$style.item">
            <a
              :class="$style.linkDownload"
              :href="currentPath"
              download="screenshot.jpg"
            >
              <inline-svg
                :src="
                  require('@/assets/images/icons/computer/screenshots/modal/download.svg')
                "
                alt="Загрузить"
                title="Загрузить"
              />
            </a>
          </div>
        </div>
      </footer>
    </template>
  </modal>
</template>

<script>
import { InlineSvgComponent } from 'vue-inline-svg';
import Modal from '@/components/common/modal/index.vue';
import { getScreenshotUrl } from '@/helpers/methods';
import ButtonBlueIcon from '@/components/common/button/button-blue-icon.vue';
import TwoArrowsRadio from '@/components/common/button/two-arrows-radio.vue';

export default {
  components: {
    ButtonBlueIcon,
    inlineSvg: InlineSvgComponent,
    TwoArrowsRadio,
    Modal,
  },
  props: {
    screenshots: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    slideshow: {
      forward: true,
      interval: 1000,
      timeout: null,
    },
  }),
  computed: {
    slideshowEnabled() {
      return !!this.slideshow.timeout;
    },
    currentId() {
      return parseInt(this.$route.params.record, 10);
    },
    currentIndex() {
      return this.screenshots.findIndex((i) => i.id === this.currentId);
    },
    currentItem() {
      return this.screenshots.find((i) => i.id === this.currentId);
    },
    currentPath() {
      return getScreenshotUrl.call(this, this.currentItem.path);
    },
    isFirst() {
      return this.currentIndex <= 0;
    },
    isLast() {
      return this.currentIndex >= this.screenshots.length - 1;
    },
  },
  mounted() {
    window.addEventListener('keydown', this.keydownListener);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keydownListener);
  },
  methods: {
    /**
     * @param {KeyboardEvent} e
     */
    keydownListener(e) {
      if (e.key === 'ArrowLeft') {
        this.prev();
        e.preventDefault();
        e.stopPropagation();
      } else if (e.key === 'ArrowRight') {
        this.next();
        e.preventDefault();
        e.stopPropagation();
      } else if (e.key === 'Escape') {
        // FIXME: Возможно, не самое лучшая реализация
        this.close();
        e.preventDefault();
        e.stopPropagation();
      }
    },
    close() {
      const query = { ...this.$route.query };
      delete query.modal;

      this.$router.replace({
        query,
      });
    },
    switchScreenshot(item) {
      if (item) {
        this.$router.replace({
          params: {
            record: item.id,
          },
          query: this.$route.query,
        });
      }
    },
    prev() {
      this.switchScreenshot(this.screenshots[this.currentIndex - 1]);
    },
    next() {
      this.switchScreenshot(this.screenshots[this.currentIndex + 1]);
    },
    first() {
      this.switchScreenshot(this.screenshots[0]);
    },
    last() {
      this.switchScreenshot(
        this.switchScreenshot(this.screenshots[this.screenshots.length - 1]),
      );
    },
    slideshowStart() {
      if (!this.slideshow.timeout) {
        this.slideshowTick();
      }
    },
    slideshowStop() {
      clearTimeout(this.slideshow.timeout);
      this.slideshow.timeout = null;
    },
    slideshowTick() {
      this.slideshow.timeout = setTimeout(() => {
        if (this.slideshow.forward) {
          this.next();
        } else {
          this.prev();
        }

        this.slideshowTick();
      }, this.slideshow.interval);
    },
  },
};
</script>

<style lang="scss" module>
.content {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageWrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 30px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.footer {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
}

.group {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

.item {
  flex-shrink: 0;

  &:not(:last-child) {
    margin-right: 6px;
  }
}

.delimeter {
  align-self: stretch;
  border-right: 1px solid $borderColor;
  margin: 0 10px;
}

.slideshowDirection {
  font-size: 12px;
}

.wrapperSelect {
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    fill: $dark-gray;
    transition: transform 0.3s;
  }

  .selectFps:focus + svg {
    fill: $blue;
    transform: translateY(-50%) rotate(180deg);
  }
}

.selectFps {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: $light-gray;
  border: none;
  border-radius: 6px;
  outline: none;
  padding: 10px;
  padding-right: 30px;
}

.linkDownload {
  cursor: pointer;
  display: inline-block;
  padding: 10px;
  background-color: $light-blue;
  border-radius: 6px;
  border: 0;

  svg path,
  svg rect {
    fill: $blue;
  }
}
</style>
