<template>
  <div id="printable">
    <v-table
      :row-active-id="rowActiveId"
      :data="tableData"
      :columns="columns"
      clickable
      @row-click="onRowClick"
      @row-dblclick="saveFile"
    >
      <template v-slot:column-time="item">
        <p
          v-if="item.row.isNew"
          :class="$style.new"
        >
          NEW
        </p>
        {{ item.row.date | dateTime }}
      </template>
      <template v-slot:column-data="item">
        <contextmenu :buttons="getContextmenuButtons(item.row.id)">
          {{ item.row.filepath || item.row.error }}
        </contextmenu>
      </template>
      <template v-slot:column-duration="item">
        {{ item.row.duration | duration }}
      </template>
    </v-table>
  </div>
</template>

<script>
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import VTable from '@/components/common/table/table.vue';
import { dateTime, duration } from '@/helpers/filters';
import { getWebcamsAndMicrophonesFile } from '@/api/methods/computers';
import Contextmenu from '@/components/common/contextmenu/index.vue';
// import AdditionalList from '@/components/common/logs/additional/list/index.vue';
import { type } from './utils';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

export default {
  components: {
    VTable,
    Contextmenu,
    // AdditionalList,
  },
  filters: {
    dateTime,
    type,
    duration,
  },
  props: {
    data: {
      type: null,
      required: true,
    },
  },
  data: () => ({
    columns: [
      {
        field: 'time',
        title: 'Время',
        sortFn: (isAsc) => (a, b) => (a.date - b.date) * (isAsc ? 1 : -1),
      },
      { field: 'data', title: 'Заголовок окна' },
      { field: 'duration', title: 'Длительность' },
    ],
  }),
  computed: {
    rowActiveId() {
      return parseInt(this.$route.params.record, 10);
    },
    tableData() {
      return this.data;
    },
    tableDataParameters() {
      return this.tableData.reduce((acc, item) => {
        if (!item.success) {
          acc[item.id] = { color: 'red' };
        }

        return acc;
      }, {});
    },
  },
  methods: {
    onRowClick(id) {
      this.$router.push({
        name: 'computers-computer-log-record',
        params: { record: id },
        query: this.$route.query,
      });
    },
    async saveFile(id) {
      if (this.isItemSuccess(id)) {
        const result = await getWebcamsAndMicrophonesFile(
          parseInt(this.$route.params.computer, 10),
          parseInt(id, 10),
        );
        if (!result.success) {
          Vue.$toast.open({
            message: result.message,
            type: 'error',
          });
        }
      }
    },
    isItemSuccess(id) {
      return this.tableData.find((i) => i.id === id).success;
    },
    getContextmenuButtons(id) {
      return this.isItemSuccess(id)
        ? [
          {
            label: 'Сохранить файл',
            onClick: () => this.saveFile(id),
          },
        ]
        : [];
    },
  },
};
</script>

<style lang="scss" module>
.new {
  display: inline-block;
  background: $light-blue;
  color: $blue;
  font-size: 7px;
  padding: 3px;
  margin-right: 8px;
  border-radius: 1px;
}
</style>
