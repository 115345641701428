<template>
  <table :class="$style.table">
    <thead>
      <tr>
        <th
          v-for="column in columns"
          :key="column.field"
          @click="onColumnClick(column.field)"
        >
          {{ column.title }}
          <span
            v-if="sortedColumn === column.field"
            :class="[$style.sort, { [$style.desc]: !isSortAscending }]"
          >
            ▾
          </span>
        </th>
      </tr>
    </thead>
    <tbody
      v-if="tableData.length"
      :class="{ [$style.clickable]: clickable }"
    >
      <tr
        v-for="(row, idx) in tableData"
        :key="row.id"
        :tabindex="idx + 1"
        :class="[
          { [$style.active]: row.id === rowActiveId },
          getRowParameterClasses(row.id),
        ]"
        @click="onRowClick(row.id)"
        @dblclick="onRowDblClick(row.id)"
        @keydown.arrow-up.prevent="focusRow(idx - 1)"
        @keydown.arrow-down.prevent="focusRow(idx + 1)"
        @focus="onRowClick(row.id)"
      >
        <td
          v-for="(column, index) in columns"
          :key="column.field"
          :class="{
            [$style.firstColumn]: index === 0,
            [$style.lastColumn]: index === columns.length - 1,
          }"
        >
          <slot
            v-if="hasDataColumnSlot(column.field)"
            :name="`column-${column.field}`"
            :row="row"
          />
          <template v-else>
            {{ row[column.field] }}<template />
          </template>
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td :colspan="columns.length">
          Данные отсутствуют
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  model: {
    prop: 'rowActiveId',
    event: 'row-click',
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    rowActiveId: {
      type: [Number, String],
      default: null,
    },
  },
  data: () => ({
    sortedColumn: null,
    isSortAscending: true,
  }),
  computed: {
    dataParameters() {
      return this.data.reduce((acc, item) => {
        acc[item.id] = {
          color: {
            1: 'yellow',
            2: 'red',
          }[item.statusDangerous],
        };

        return acc;
      }, {});
    },
    tableData() {
      const { sortedColumn, isSortAscending } = this;

      const sortedColumnObject = this.columns.find(
        (i) => i.field === sortedColumn,
      );

      if (
        sortedColumnObject
        && this.hasDataColumnSlot(sortedColumn)
        && typeof sortedColumnObject.sortFn === 'function'
      ) {
        return [...this.data].sort(sortedColumnObject.sortFn(isSortAscending));
      }

      return [...this.data].sort(this.compare);
    },
  },
  methods: {
    compare(a, b) {
      a = a[this.sortedColumn];
      b = b[this.sortedColumn];

      if (typeof a === 'string' && typeof b === 'string') {
        a = a.toLowerCase();
        b = b.toLowerCase();
      }

      if (this.isSortAscending) {
        if (a > b) {
          return -1;
        }

        if (a < b) {
          return 1;
        }
        return 0;
      }

      if (a < b) {
        return -1;
      }

      if (a > b) {
        return 1;
      }

      return 0;
    },
    hasDataColumnSlot(field) {
      return Object.prototype.hasOwnProperty.call(
        this.$scopedSlots,
        `column-${field}`,
      );
    },
    isSortedColumn(column) {
      return this.sortedColumn === column;
    },
    onColumnClick(column) {
      if (column === this.sortedColumn) {
        this.isSortAscending = !this.isSortAscending;
      } else {
        this.sortedColumn = column;
        this.isSortAscending = true;
      }
    },
    onRowClick(id) {
      if (this.clickable && this.rowActiveId !== id) {
        // Второе условие - костыль
        this.$emit('row-click', id);
      }
    },
    onRowDblClick(id) {
      if (this.clickable) {
        this.$emit('row-dblclick', id);
      }
    },
    focusRow(idx) {
      const elems = document.getElementsByTagName('tr');

      for (let i = elems.length; i--;) {
        const tidx = parseInt(elems[i].getAttribute('tabindex'), 10);
        if (tidx === idx + 1) {
          elems[i].focus();
        }
      }
    },
    getRowParameterClasses(rowId) {
      const row = this.dataParameters[rowId];

      if (!row) {
        return [];
      }
      const classes = [];

      // eslint-disable-next-line default-case
      switch (row.color) {
        case 'yellow':
          classes.push(this.$style.colorYellow);
          break;
        case 'red':
          classes.push(this.$style.colorRed);
          break;
      }

      return classes;
    },
  },
};
</script>

<style lang="scss" module>
.table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  td,
  th {
    border: 1px solid $borderColor;
    padding: 6px;
    word-break: break-all;
  }

  .firstColumn {
    padding-left: 20px;
  }

  .lastColumn {
    padding-right: 20px;
  }

  th {
    user-select: none;
    background: $light-gray;
    font: 400 13px/18px Inter, sans-serif;
    cursor: pointer;
    background: #dbf1ff;
  }

  td {
    font-size: 12px;
  }

  tbody.clickable tr {
    cursor: pointer;

    &:hover {
      background: $light-gray;
    }

    &.active {
      background: $light-gray;
    }
  }

  tr.colorYellow {
    background-color: $light-yellow;

    &:hover {
      background: $yellow !important;
    }

    &.active {
      background: $yellow !important;
    }
  }

  tr.colorRed {
    background-color: $light-red;

    &:hover {
      background: $red !important;
    }

    &.active {
      background: $red !important;
    }
  }
}

.sort {
  display: inline-block;
  margin-left: 6px;
  color: $dark-gray;
}

.desc {
  transform: rotate(180deg);
}
</style>
