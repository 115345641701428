<template>
  <div :class="style" />
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    dangerousState() {
      switch (this.value) {
        case 1: return this.$style.yellow;
        case 2: return this.$style.red;
        default: return this.$style.green;
      }
    },
    style() {
      return [this.$style.dangerous, this.dangerousState];
    },
  },
};
</script>

<style lang="scss" module>
.dangerous {
  display: inline-block;
  vertical-align: text-top;
  width: 12px;
  height: 12px;
  border-radius: 50%;

  &.green {
    background: $green;
  }

  &.yellow {
    background: $yellow;
  }

  &.red {
    background: $red;
  }
}
</style>
