<template>
  <div id="printable">
    <div v-if="isDataLoading">
      Загрузка...
    </div>
    <v-table v-else :row-active-id="rowActiveId" :data="tableData" :columns="columns" clickable @row-click="onRowClick">
      <template v-slot:column-time="item">
        <p v-if="item.row.isNew" :class="$style.new">
          NEW
        </p>
        {{ item.row.date | dateTime }}
      </template>
      <template v-slot:column-caption="item">
        <contextmenu :buttons="getContextmenuButtons(item.row)">
          <span :class="$style.dangerous">
            <dangerous :value="item.row.statusDangerous" />
          </span>
          {{ item.row.caption }}
        </contextmenu>
      </template>

      <template v-slot:column-type="item">
        {{ item.row.type | type }}
      </template>
    </v-table>
    <component :is="additionalComponent" v-if="rowActiveId" v-bind="additionalProps" @image-dblclick="openModal" />
    <modal v-if="isShowModal" :title="activeRow.caption" :fullscreen="true" @close="close">
      <div :class="$style.content">
        <div :class="$style.imageWrapper">
          <img :src="activeLink">
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import VTable from '@/components/common/table/table.vue';
import { dateTime } from '@/helpers/filters';
import Dangerous from '@/components/common/dangerous/index.vue';
import AdditionalList from '@/components/common/logs/additional/list/index.vue';
import AdditionalListImage from '@/components/common/logs/additional/list-image/index.vue';
import Contextmenu from '@/components/common/contextmenu/index.vue';
import { LogData } from '@/domains/log/data';
import {
  getClipboardFile,
  getClipboardFileLink,
} from '@/api/methods/computers';
import Modal from '@/components/common/modal/index.vue';
import 'vue-toast-notification/dist/theme-default.css';
import workerBasedRequestMixin from '@/components/common/logs/mixins/workerBasedRequestMixin';
import { type } from './utils';

Vue.use(VueToast);

export default {
  components: {
    VTable,
    Dangerous,
    Contextmenu,
    Modal,
  },
  filters: {
    dateTime,
    type,
  },
  mixins: [workerBasedRequestMixin],
  data: () => ({
    tableData: [],
    columns: [
      {
        field: 'time',
        title: 'Время',
        sortFn: (isAsc) => (a, b) => (new Date(a.date) - new Date(b.date)) * (isAsc ? 1 : -1),
      },
      { field: 'caption', title: 'Заголовок окна' },
      { field: 'username', title: 'Имя пользователя' },
      { field: 'type', title: 'Тип данных' },
    ],
  }),
  computed: {
    isShowModal() {
      return Object.prototype.hasOwnProperty.call(this.$route.query, 'modal');
    },
    rowActiveId() {
      return parseInt(this.$route.params.record, 10);
    },
    activeRow() {
      const activeRow = this.pagedData.find((i) => i.id === this.rowActiveId);

      return activeRow || {};
    },
    additionalComponent() {
      switch (this.activeRow.type) {
        case 2:
          return AdditionalListImage;
        default:
          return AdditionalList;
      }
    },
    activeLink() {
      return getClipboardFileLink(
        parseInt(this.$route.params.computer, 10),
        this.rowActiveId,
      );
    },
    additionalProps() {
      const row = this.activeRow;

      const props = {
        list: [
          ['Время', dateTime(row.date)],
          ['Заголовок окна', row.caption],
          ['Тип данных', type(row.type)],
          ...(row.type === 0 ? [['Содержимое буфера обмена', row.text]] : []),
          ['Имя пользователя', row.username],
        ],
      };

      if (row.type === 2) {
        props.image = this.activeLink;
      }

      return props;
    },
  },
  watch: {
    '$route.query': function (query, oldQuery) {
      // eslint-disable-line func-names
      if (
        query.dateFrom !== oldQuery.dateFrom
        || query.dateTo !== oldQuery.dateTo
      ) {
        this.fetch();
      }
      if (query.filter !== oldQuery.filter) {
        this.getTableData();
      }
    },
    pagedData() {
      this.getTableData();
    },
  },
  async created() {
    await this.fetch();
  },
  methods: {
    getTableData() {
      let items = [...this.pagedData];
      items = items.filter((element) => element !== undefined);

      if (items.length > 0) {
        this.tableData = LogData.filterTable(
          items,
          [(item) => dateTime(item.date), 'caption', 'text', 'username'],
          this.$route.query.filter,
        );
      }
    },
    openModal() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          modal: null,
        },
      });
    },
    close() {
      const query = { ...this.$route.query };
      delete query.modal;

      this.$router.replace({
        query,
      });
    },
    onRowClick(id) {
      this.$router.push({
        name: 'computers-computer-log-record',
        params: { record: id },
        query: this.$route.query,
      });
    },
    async getFile() {
      const result = await getClipboardFile(
        parseInt(this.$route.params.computer, 10),
        this.rowActiveId,
      );
      if (!result.success) {
        Vue.$toast.open({
          message: result.message,
          type: 'error',
        });
      }
    },
    getContextmenuButtons(item) {
      return item.type === 0
        ? []
        : [
          {
            label: 'Сохранить файл',
            onClick: () => this.getFile(),
          },
        ];
    },
  },
};
</script>

<style lang="scss" module>
.new {
  display: inline-block;
  background: $light-blue;
  color: $blue;
  font-size: 7px;
  padding: 3px;
  margin-right: 8px;
  border-radius: 1px;
}

.dangerous {
  margin-right: 8px;
}

.tableColumnText {
  word-break: break-all;
}

.content {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageWrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 30px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}
</style>
