<template>
  <div :class="$style.buttonWrapper">
    <button
      :class="[{[$style.active]: !right}, $style.button]"
      @click="$emit('change', false)"
    >
      <svg
        width="8"
        height="13"
        viewBox="0 0 8 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <!-- eslint-disable-next-line max-len -->
        <path d="M1.22903 6.25848L6.38884 1.09883C6.71706 0.770442 7.24923 0.770442 7.57729 1.09883C7.90539 1.42692 7.90539 1.95906 7.57729 2.28712L3.01166 6.85263L7.57716 11.418C7.90525 11.7462 7.90525 12.2783 7.57716 12.6064C7.24907 12.9346 6.71693 12.9346 6.38871 12.6064L1.22889 7.44664C1.06485 7.28251 0.982916 7.06764 0.982916 6.85265C0.982916 6.63756 1.06501 6.42252 1.22903 6.25848Z" />
      </svg>
    </button>
    <div :class="[$style.slot, slotClass]">
      <slot />
    </div>
    <button
      :class="[{[$style.active]: right}, $style.button]"
      @click="$emit('change', true)"
    >
      <svg
        width="7"
        height="13"
        viewBox="0 0 7 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <!-- eslint-disable-next-line max-len -->
        <path d="M6.75388 7.48078L1.59407 12.6404C1.26585 12.9688 0.733682 12.9688 0.405615 12.6404C0.0775219 12.3123 0.0775219 11.7802 0.405615 11.4521L4.97125 6.88663L0.405749 2.32129C0.0776552 1.99306 0.0776552 1.46098 0.405749 1.13289C0.733842 0.804662 1.26598 0.804662 1.5942 1.13289L6.75402 6.29262C6.91806 6.45674 6.99999 6.67162 6.99999 6.88661C6.99999 7.1017 6.9179 7.31673 6.75388 7.48078Z" />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'right',
    event: 'change',
  },
  props: {
    right: {
      type: Boolean,
      required: true,
    },
    slotClass: {
      type: null,
      default: null,
    },
  },
};
</script>

<style lang="scss" module>
.buttonWrapper {
  background: $light-gray;
  border-radius: 6px;
  padding: 8px 14px;
  display: flex;
  align-items: center;
}

.slot {
  padding: 0 18px;
  flex: 1 1 100%;
}

.button {
  background: none;
  flex-shrink: 0;
  border: 0;
  cursor: pointer;

  path {
      fill: $dark-gray;
    }

  &.active {
    path {
      fill: $blue;
    }
  }
}
</style>
