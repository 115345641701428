<template>
  <input
    :value="value"
    :class="$style.input"
    :type="type"
    :placeholder="placeholder"
    @input="e => $emit('input', e.target.value)"
  >
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" module>
.input {
  width: 100%;
  border: 1px solid $borderColor;
  color: $black;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 12px;

  &::placeholder {
    color: dakren($dark-gray, 3%);
  }
}
</style>
