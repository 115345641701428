const actionDict = {
  3: 'Включение компьютера',
  4: 'Выключение компьютера',
  23: 'Начало простоя',
  24: 'Окончание простоя',
  25: 'Начало сеанса пользователя',
  26: 'Завершение сеанса пользователя',
  27: 'Запуск ScreenSaver',
  28: 'Завершение ScreenSaver',
  29: 'Блокировка рабочей станции',
  30: 'Разблокировка рабочей станции',
};

export const action = (val) => actionDict[val];
