<template>
  <base-collapse>
    <div :class="$style.content">
      <div :class="$style.image">
        <img
          :src="image"
          @dblclick="$emit('image-dblclick')"
        >
      </div>
      <partial-list :list="list" />
    </div>
  </base-collapse>
</template>

<script>
import BaseCollapse from '../_base/collapse.vue';
import PartialList from '../_partials/list.vue';

export default {
  components: {
    BaseCollapse,
    PartialList,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    image: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" module>
.content {
  display: grid;
  grid-template-columns: 3fr 5fr;
}

.image {
  background: $light-blue;
  padding: 20px;

  img {
    cursor: pointer;
  }
}
</style>
