<template>
  <div>
    <contextmenu :buttons="contextmenuButtons">
      <template v-if="row.attachment">
        <img :src="icon" :class="$style.icon">
        <span :class="$style.text">{{ row.attachment.filename }}</span>
      </template>
      <template v-else>
        {{ row.text }}
      </template>
    </contextmenu>
    <context-menu-modal
      v-if="isShowModal"
      @update="() => null"
      @close="isShowModal = false"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import Contextmenu from '@/components/common/contextmenu/index.vue';
import IconSpeaker from '@/assets/images/icons/logs/messengers/speaker.svg';
import IconAttachment from '@/assets/images/icons/logs/messengers/attachment.svg';
import { getMessengersAttachment } from '@/api/methods/computers';
import ContextMenuModal from './context-menu-modal.vue';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

export default {
  components: {
    Contextmenu,
    ContextMenuModal,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
    recordId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isShowModal: false,
  }),
  computed: {
    hasAttachment() {
      return !!this.row.attachment;
    },
    contextmenuButtons() {
      return [
        // TODO: Форма изменения никнейма
        // {
        //   label: 'Задать обозначение для UIN',
        //   onClick: () => { this.isShowModal = true; },
        // },
        ...(this.hasAttachment
          ? [
            {
              label: 'Сохранить файл',
              onClick: () => this.getAttachment(),
            },
          ]
          : []),
      ];
    },
    attachmentType() {
      return this.hasAttachment ? this.row.attachment.type : null;
    },
    icon() {
      switch (this.attachmentType) {
        case 'audio':
          return IconSpeaker;
        case 'file':
          return IconAttachment;
        default:
          return null;
      }
    },
  },
  methods: {
    async getAttachment() {
      const result = await getMessengersAttachment(
        parseInt(this.$route.params.computer, 10),
        this.recordId,
      );
      if (!result.success) {
        Vue.$toast.open({
          message: result.message,
          type: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" module>
.icon {
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}
.text {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}
</style>
