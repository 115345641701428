<template>
  <div :class="$style.wrapper">
    <button :class="$style.btnAccordion" @click="isOpened = !isOpened">
      <span>Дополнительная информация</span>
      <span :class="{ [$style.caretClosed]: !isOpened }">
        <img
          src="@/assets/images/icons/computer/screenshots/additional-angle.svg"
        >
      </span>
    </button>
    <div v-if="isOpened" :class="$style.content">
      <div :class="$style.attachments">
        <div :class="$style.attachmentsTitle">
          Вложенные файлы
        </div>
        <ul v-if="attachments" :class="$style.attachmentsList">
          <li v-for="attachment in attachments" :key="attachment.id">
            {{ getAttachmentTitle(attachment) }}
          </li>
        </ul>
        <div v-else>
          Отсутствуют
        </div>
        <button @click="openMail">
          Открыть письмо
        </button>
      </div>
      <div :class="$style.description">
        <table v-if="tab === 'info'" :class="$style.info">
          <tbody>
            <tr>
              <th>Время</th>
              <td>{{ date | dateTime }}</td>
            </tr>
            <tr>
              <th>Дата отправки</th>
              <td>{{ dateSend }}</td>
            </tr>
            <tr>
              <th>Тема письма</th>
              <td>{{ subject }}</td>
            </tr>
            <tr>
              <th>От кого</th>
              <td>{{ from }}</td>
            </tr>
            <tr>
              <th>Кому</th>
              <td>{{ to }}</td>
            </tr>
            <tr>
              <th>Текст сообщения</th>
              <td>{{ text }}</td>
            </tr>
            <tr>
              <th>Имя пользователя</th>
              <td>{{ username }}</td>
            </tr>
          </tbody>
        </table>
        <div v-if="tab === 'raw'" :class="$style.raw">
          {{ textRaw }}
        </div>
        <button @click.prevent="tab = 'info'">
          Info
        </button>
        <button @click.prevent="tab = 'raw'">
          HTML
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { dateTime } from '@/helpers/filters';
import { type } from '../utils';

export default {
  filters: {
    dateTime,
    type,
  },
  props: {
    date: {
      type: Date,
      required: true,
    },
    dateSend: {
      type: String,
      required: true,
    },
    from: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
    subject: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    textRaw: {
      type: String,
      required: true,
    },
    username: {
      type: String,
      required: true,
    },
    attachments: {
      type: Array,
      required: true,
    },
    openMail: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    isOpened: true,
    tab: 'info',
  }),
  methods: {
    getAttachmentTitle(att) {
      return `${att.filename}`;
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  position: sticky;
  bottom: 0;
  border: 1px solid $borderColor;
  background: $white;
}

.btnAccordion {
  background: $light-gray;
  cursor: pointer;
  font-size: 13px;
  user-select: none;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
}

.caretClosed {
  transform: rotate(180deg);
}

.content {
  display: flex;
}

.attachments {
  padding: 5px 20px;
  flex-shrink: 0;
  border-right: 1px solid $borderColor;
}

.attachmentsTitle {
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 5px;
}

.attachmentsList {
  font-size: 12px;

  li {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.description {
  flex-grow: 1;
}

.info {
  padding: 5px 0;
  border-collapse: separate;
  border-spacing: 20px 5px;
  th {
    text-align: left;
    font-weight: bold;
    font-size: 13px;
  }
  td {
    font-size: 12px;
    word-break: break-word;
  }
}

.raw {
  padding: 5px 0;
}
</style>
