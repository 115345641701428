<template>
  <div :class="$style.grid">
    <div v-for="(item, idx) in filteredScreenshots" ref="item" :key="item.id" :tabindex="idx + 1"
         :class="getItemClasses(item.id)" @dblclick="openModal" @focus="navigate(item.id)"
         @keydown.arrow-up.prevent="onArrowUp(idx)" @keydown.arrow-left.prevent="onArrowLeft(idx)"
         @keydown.arrow-right.prevent="onArrowRight(idx)" @keydown.arrow-down.prevent="onArrowDown(idx)"
    >
      <div :class="$style.title">
        {{ item.date | dateTime }} / {{ item.caption }}
      </div>
      <img :src="getScreenshotUrl(item.path)">
    </div>
  </div>
</template>

<script>
import { dateTime } from '@/helpers/filters';
import { getScreenshotUrl } from '@/helpers/methods';
import { LogData } from '@/domains/log/data';

export default {
  filters: {
    dateTime,
  },
  props: {
    screenshots: {
      type: Array,
      required: true,
    },
  },
  computed: {
    filteredScreenshots() {
      return LogData.filterTable(
        this.screenshots,
        [(item) => dateTime(item.date), 'caption'],
        this.$route.query.filter,
      );
    },
  },
  methods: {
    getItemClasses(id) {
      const isActive = parseInt(this.$route.params.record, 10) === id;

      return [
        this.$style.itemTable,
        { [this.$style.itemTableActive]: isActive },
      ];
    },
    getScreenshotUrl,
    openModal() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          modal: null,
        },
      });
    },
    navigate(id) {
      this.$router.replace({
        name: 'computers-computer-log-record',
        params: { record: id },
        query: this.$route.query,
      });
    },
    onArrowUp(idx) {
      this.focus(idx - 3);
    },
    onArrowLeft(idx) {
      this.focus(idx - 1);
    },
    onArrowRight(idx) {
      this.focus(idx + 1);
    },
    onArrowDown(idx) {
      this.focus(idx + 3);
    },
    focus(index) {
      const item = this.$refs.item[index];

      if (item) {
        item.focus();
      }
    },
  },
};
</script>

<style lang="scss" module>
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-top: 1px solid $borderColor;
  border-left: 1px solid $borderColor;
}

.itemTable {
  cursor: pointer;
  text-decoration: none;
  padding: 10px;
  background: $white;
  border-right: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
}

.itemTableActive {
  background: $light-blue;
}

.title {
  font-size: 12px;
  margin-bottom: 6px;
}
</style>
