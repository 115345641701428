// eslint-disable-next-line
import Worker from 'worker-loader!@/components/common/logs/worker';
import { getResultNumber } from '@/api/methods/computers';
import dayjs from 'dayjs';

export default {
  data() {
    return {
      columns: [],
      workersNumber: 5,
      workers: [],
      pageSize: 100,
      pages: [],
      pagedData: [],
      isDataLoading: false,
    };
  },

  methods: {
    async fetch() {
      const count = await this.fetchCount();

      if (count <= 0) {
        return;
      }

      await this.assignJobs(count);
    },
    async assignJobs(count) {
      this.isDataLoading = true;

      this.prepareData(count);
      this.assignWorkers();
    },
    async fetchCount() {
      this.logTypeSlug = this.$route.params.log;
      this.computerId = parseInt(this.$route.params.computer, 10);

      this.dateFrom = dayjs(this.$route.query.dateFrom)
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0);

      this.dateTo = dayjs(this.$route.query.dateTo)
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59)
        .set('millisecond', 0);

      if (this.logTypeSlug !== 'messengers') {
        this.lastId = this.$store.getters['historyLogs/getLastId'](this.logTypeSlug, this.computerId);
      } else {
        const lastText = this.$store.getters['historyLogs/getLastId'](`${this.logTypeSlug}text`, this.computerId);
        const lastFile = this.$store.getters['historyLogs/getLastId'](`${this.logTypeSlug}file`, this.computerId);

        this.lastId = lastText > lastFile ? lastText : lastFile;
      }

      const count = await getResultNumber(
        this.computerId,
        this.logTypeSlug,
        this.dateFrom,
        this.dateTo,
      );

      return count?.result;
    },
    prepareData(count) {
      this.pages = [];
      this.pagedData = Array(count);

      for (let i = 0; i < count / this.pageSize; i++) {
        this.pages.push(false);
      }
    },
    assignWorkers() {
      this.workers = [];
      let workersNum = this.workersNumber;

      if (this.pages.length < workersNum) {
        workersNum = this.pages.length;
      }

      for (let i = 0; i < workersNum; i++) {
        this.workers.push(this.createWorker(i));
      }
    },
    createWorker(workerIndex) {
      const obj = {
        worker: new Worker(), isBusy: false,
      };
      obj.worker.onmessage = (result) => {
        const res = JSON.parse(result.data);

        if (res.items === 'error') {
          this.pages[res.index] = false;
        } else {
          this.onDataRecieved(res);

          if (this.isDataLoading) this.isDataLoading = false;
        }

        this.workers[workerIndex].isBusy = false;
      };

      return obj;
    },
    onDataRecieved(data) {
      this.pagedData.splice(data.index * this.pageSize, data.items.length, ...data.items);

      if (!this.pagedData.includes(undefined)) this.onAllItemsReceived();
    },
    onAllItemsReceived() {
      const items = [...this.pagedData];

      if (this.logTypeSlug === 'messengers') {
        const textLogs = [...items].filter((item) => item.type === 'text');
        const fileLogs = [...items].filter((item) => item.type === 'file');

        this.$store.dispatch('historyLogs/setLastId', {
          id: textLogs.reduce((acc, item) => (item.idRecord > acc ? item.idRecord : acc), 0),
          logTypeSlug: `${this.logTypeSlug}text`,
          idComputer: this.computerId,
        });

        this.$store.dispatch('historyLogs/setLastId', {
          id: fileLogs.reduce((acc, item) => (item.idRecord > acc ? item.idRecord : acc), 0),
          logTypeSlug: `${this.logTypeSlug}file`,
          idComputer: this.computerId,
        });
      } else {
        this.$store.dispatch('historyLogs/setLastId', {
          id: items.reduce((acc, item) => (item.id > acc ? item.id : acc), 0),
          logTypeSlug: this.logTypeSlug,
          idComputer: this.computerId,
        });
      }

      /*  const exportArray = [];
        items.forEach((item) => {
          const resObj = {};
          for (let j = 0; j < this.columns.length; j++) {
            if (this.columns[j].field === 'time') resObj[this.columns[j].title] = dateTime(item.date);
            else resObj[this.columns[j].title] = item[this.columns[j].field];
          }
          exportArray.push(resObj);
        });

        this.$store.commit('exportData/setLogs', exportArray); */
    },
  },

  watch: {
    workers: {
      handler(newWorkers) {
        const worker = newWorkers.find((one) => one.isBusy === false);

        if (worker) {
          const index = this.pages.findIndex((i) => i === false);
          if (index > -1) {
            worker.isBusy = true;
            this.pages[index] = true;

            worker.worker.postMessage(JSON.stringify({
              index,
              computerId: this.computerId,
              logTypeSlug: this.logTypeSlug,
              lastId: this.lastId,
              dateFrom: this.dateFrom,
              dateTo: this.dateTo,
              pageSize: this.pageSize,
            }));
          }
        }
      },
      deep: true,
    },
  },
};
