<template>
  <div id="printable">
    <v-table
      :row-active-id="rowActiveId"
      :data="tableData"
      :columns="columns"
      clickable
      @row-click="onRowClick"
    >
      <template v-slot:column-time="item">
        <p v-if="item.row.isNew" :class="$style.new">
          NEW
        </p>
        {{ item.row.date | dateTime }}
      </template>
      <template v-slot:column-caption="item">
        <span :class="$style.dangerous">
          <dangerous :value="item.row.statusDangerous" />
        </span>
        {{ item.row.caption }}
      </template>

      <template v-slot:column-status="item">
        {{ item.row.status | status }}
      </template>
    </v-table>
    <additional-list v-if="rowActiveId" :list="additionalList" />
  </div>
</template>

<script>
import VTable from '@/components/common/table/table.vue';
import { dateTime } from '@/helpers/filters';
import AdditionalList from '@/components/common/logs/additional/list/index.vue';
import Dangerous from '@/components/common/dangerous/index.vue';
import { LogData } from '@/domains/log/data';
import { status } from './utils';

export default {
  components: {
    VTable,
    AdditionalList,
    Dangerous,
  },
  filters: {
    dateTime,
    status,
  },
  props: {
    data: {
      type: null,
      required: true,
    },
  },
  data: () => ({
    columns: [
      {
        field: 'time',
        title: 'Время',
        sortFn: (isAsc) => (a, b) => (a.date - b.date) * (isAsc ? 1 : -1),
      },
      { field: 'category', title: 'Категория' },
      { field: 'name', title: 'Программа' },
      { field: 'status', title: 'Действие' },
      { field: 'caption', title: 'Заголовок окна' },
      { field: 'path', title: 'Путь к программе' },
    ],
  }),
  computed: {
    rowActiveId() {
      return parseInt(this.$route.params.record, 10);
    },
    tableData() {
      const { category } = this.$route.query;

      let items = this.data;

      if (category) {
        items = items.filter((item) => item.category === category);
      }

      return LogData.filterTable(
        items,
        [
          (item) => dateTime(item.date),
          (item) => status(item.status),
          'caption',
          'path',
          'username',
        ],
        this.$route.query.filter,
      );
    },
    activeRow() {
      const activeRow = this.data.find((i) => i.id === this.rowActiveId);

      return activeRow || {};
    },
    additionalList() {
      const row = this.activeRow;

      return [
        ['Время', dateTime(row.date)],
        ['Категория', row.category],
        ['Действие', status(row.status)],
        ['Программа', row.name],
        ['Заголовок окна', row.caption],
        ['Путь к программе', row.path],
        ['Имя пользователя', row.username],
      ];
    },
  },
  watch: {
    data() {
      this.setCategoriesState();
    },
  },
  created() {
    this.setCategoriesState();
  },
  methods: {
    setCategoriesState() {
      const categories = this.data.reduce((acc, item) => {
        const { category } = item;
        if (!acc.includes(category)) {
          acc.push(category);
        }
        return acc;
      }, []);

      this.$store.commit('logs/programs/setCategories', categories);
    },
    onRowClick(id) {
      this.$router.push({
        name: 'computers-computer-log-record',
        params: { record: id },
        query: this.$route.query,
      });
    },
  },
};
</script>

<style lang="scss" module>
.new {
  display: inline-block;
  background: $light-blue;
  color: $blue;
  font-size: 7px;
  padding: 3px;
  margin-right: 8px;
  border-radius: 1px;
}

.dangerous {
  display: inline-block;
  margin-right: 8px;
}
</style>
