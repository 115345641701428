<template>
  <div id="printable">
    <v-table
      :row-active-id="rowActiveId"
      :data="tableData"
      :columns="columns"
      clickable
      @row-click="onRowClick"
      @row-dblclick="downloadPrinterDocument"
    >
      <template v-slot:column-time="item">
        <p v-if="item.row.isNew" :class="$style.new">
          NEW
        </p>
        {{ item.row.date | dateTime }}
      </template>
      <template v-slot:column-printer="item">
        <span :class="$style.dangerous">
          <dangerous :value="item.row.statusDangerous" />
        </span>
        {{ item.row.printer }}
      </template>
      <template v-slot:column-document="item">
        <contextmenu :buttons="getContextmenuButtons(item.row.id)">
          {{ item.row.document }}
        </contextmenu>
      </template>
    </v-table>
    <additional-list v-if="rowActiveId" :list="additionalList" />
  </div>
</template>

<script>
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import VTable from '@/components/common/table/table.vue';
import { dateTime } from '@/helpers/filters';
import { getPrinterDocumentLink } from '@/api/methods/computers';
import Dangerous from '@/components/common/dangerous/index.vue';
import AdditionalList from '@/components/common/logs/additional/list/index.vue';
import { LogData } from '@/domains/log/data';
import Contextmenu from '@/components/common/contextmenu/index.vue';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

export default {
  components: {
    VTable,
    Dangerous,
    AdditionalList,
    Contextmenu,
  },
  filters: {
    dateTime,
  },
  props: {
    data: {
      type: null,
      required: true,
    },
  },
  data: () => ({
    columns: [
      {
        field: 'time',
        title: 'Время',
        sortFn: (isAsc) => (a, b) => (a.date - b.date) * (isAsc ? 1 : -1),
      },
      { field: 'printer', title: 'Принтер' },
      { field: 'document', title: 'Документ' },
      { field: 'pagesDocument', title: 'Количество страниц' },
      { field: 'cost', title: 'Стоимость' },
      { field: 'username', title: 'Имя пользователя' },
    ],
  }),
  computed: {
    rowActiveId() {
      return parseInt(this.$route.params.record, 10);
    },
    tableData() {
      return LogData.filterTable(
        this.data,
        [
          (item) => dateTime(item.date),
          'printer',
          'document',
          'computer',
          'username',
        ],
        this.$route.query.filter,
      );
    },
    activeRow() {
      const activeRow = this.data.find((i) => i.id === this.rowActiveId);

      return activeRow || {};
    },
    additionalList() {
      const row = this.activeRow;

      return [
        ['Время', dateTime(row.date)],
        ['Принтер', row.printer],
        ['Документ', row.document],
        ['Распечатанных страниц', row.pagesPrinted],
        ['Копий', row.copies],
        ['Стоимость', row.cost],
        ['Компьютер', row.computer],
        ['Имя пользователя', row.username],
      ];
    },
  },
  methods: {
    onRowClick(id) {
      this.$router.push({
        name: 'computers-computer-log-record',
        params: { record: id },
        query: this.$route.query,
      });
    },

    async downloadPrinterDocument(id) {
      const { computer } = this.$route.params;
      const result = await getPrinterDocumentLink(computer, id);

      if (!result.success) {
        Vue.$toast.open({
          message: result.message,
          type: 'error',
        });
      }
    },
    getContextmenuButtons(id) {
      return [
        {
          label: 'Сохранить файл',
          onClick: () => this.downloadPrinterDocument(id),
        },
      ];
    },
  },
};
</script>

<style lang="scss" module>
.new {
  display: inline-block;
  background: $light-blue;
  color: $blue;
  font-size: 7px;
  padding: 3px;
  margin-right: 8px;
  border-radius: 1px;
}

.dangerous {
  display: inline-block;
  margin-right: 8px;
}
</style>
