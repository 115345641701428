<template>
  <component
    :is="additionalComponent"
    v-bind="additionalProps"
    log="messengers"
    @image-dblclick="$emit('image-dblclick')"
  />
</template>

<script>
import AdditionalList from '@/components/common/logs/additional/list/index.vue';
import AdditionalListImage from '@/components/common/logs/additional/list-image/index.vue';
import AdditionalListAudio from '@/components/common/logs/additional/list-audio/index.vue';
import AdditionalListAttachments from '@/components/common/logs/additional/list-attachments/index.vue';
import { dateTime } from '@/helpers/filters';
import { getMessengersAttachmentLink } from '@/api/methods/computers';
import { messengerName, directionName } from '../filters';

export default {
  props: {
    row: {
      type: Object,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
  computed: {
    additionalComponent() {
      if (this.row.attachment) {
        const { type } = this.row.attachment;

        if (type === 'audio') {
          return AdditionalListAudio;
        }

        if (type === 'image') {
          return AdditionalListImage;
        }

        return AdditionalListAttachments;
      }

      return AdditionalList;
    },
    additionalProps() {
      const { attachment } = this.row;

      const props = {
        list: this.additionalPropsList,
      };

      if (attachment) {
        const { link } = this;

        if (attachment.type === 'audio') {
          props.audio = {
            url: getMessengersAttachmentLink(parseInt(link, 10), this.row.id),
            contentType: attachment.contentType,
          };
        } else if (attachment.type === 'image') {
          props.image = getMessengersAttachmentLink(
            parseInt(link, 10),
            this.row.id,
          );
        } else if (attachment.type === 'file') {
          props.attachments = [
            {
              id: this.row.id,
              filename: this.row.attachment.filename,
              url: link,
            },
          ];
        }
      }

      return props;
    },
    additionalPropsList() {
      const list = [
        ['Время', dateTime(this.row.date)],
        ['Мессенджер', messengerName(this.row.messenger)],
        ['Адресат', this.row.interlocutor],
        ['Тип сообщения', directionName(this.row.direction)],
        ['Имя пользователя', this.row.username],
        ['Имя в мессенджере', this.row.usernameMessenger],
      ];

      if (this.row.text) {
        list.splice(3, 0, ['Текст сообщения', this.row.text]);
      }

      if (this.row.attachment) {
        list.splice(3, 0, ['File MD5', this.row.attachment.crc]);
      }

      return list;
    },
  },
};
</script>
