<template>
  <div id="printable">
    <div v-if="isDataLoading">
      Загрузка...
    </div>
    <v-table v-else :row-active-id="rowActiveId" :data="tableData" :columns="columns" clickable @row-click="onRowClick"
             @row-dblclick="openCurrentMail"
    >
      <template v-slot:column-time="item">
        <p v-if="item.row.isNew" :class="$style.new">
          NEW
        </p>
        {{ item.row.date | dateTime }}
      </template>
      <template v-slot:column-dateSend="item">
        {{ item.row.attachments.length ? '📎 ' : '' }} {{ item.row.dateSend }}
      </template>
      <template v-slot:column-subject="item">
        <contextmenu :buttons="getContextmenuButtons(item.row.id)">
          <span :class="$style.dangerous">
            <dangerous :value="item.row.statusDangerous" />
          </span>
          <span>{{ item.row.subject }}</span>
        </contextmenu>
      </template>
    </v-table>
    <additional v-if="rowActiveId" v-bind="activeRow" :open-mail="openCurrentMail" />
  </div>
</template>

<script>
import VTable from '@/components/common/table/table.vue';
import { dateTime } from '@/helpers/filters';
import Dangerous from '@/components/common/dangerous/index.vue';
import { getEmailClientsMailLink } from '@/api/methods/computers';
import { LogData } from '@/domains/log/data';
import Contextmenu from '@/components/common/contextmenu/index.vue';
import workerBasedRequestMixin from '@/components/common/logs/mixins/workerBasedRequestMixin';
import { type } from './utils';
import Additional from './components-default/additional.vue';

export default {
  components: {
    VTable,
    Additional,
    Dangerous,
    Contextmenu,
  },
  filters: {
    dateTime,
    type,
  },
  mixins: [workerBasedRequestMixin],
  data: () => ({
    tableData: [],
    columns: [
      {
        field: 'time',
        title: 'Время',
        sortFn: (isAsc) => (a, b) => (new Date(a.date) - new Date(b.date)) * (isAsc ? 1 : -1),
      },
      { field: 'dateSend', title: 'Дата отправки' },
      { field: 'subject', title: 'Тема письма' },
      { field: 'from', title: 'От кого' },
      { field: 'to', title: 'Кому' },
      { field: 'username', title: 'Имя пользователя' },
    ],
  }),
  computed: {
    rowActiveId() {
      return parseInt(this.$route.params.record, 10);
    },
    activeRow() {
      const activeRow = this.pagedData.find((i) => i.id === this.rowActiveId);

      return activeRow || {};
    },
  },
  watch: {
    '$route.query': function (query, oldQuery) {
      // eslint-disable-line func-names
      if (
        query.dateFrom !== oldQuery.dateFrom
        || query.dateTo !== oldQuery.dateTo
      ) {
        this.fetch();
      }
      if (query.filter !== oldQuery.filter || query.mailType !== oldQuery.mailType
        || query.attachmentOnly !== oldQuery.attachmentOnly || query.domain !== oldQuery.domain) {
        this.getTableData();
      }
    },
    pagedData() {
      this.getTableData();
    },
  },
  async created() {
    await this.fetch();
  },
  methods: {
    getTableData() {
      const { attachmentOnly } = this.$route.query;
      const { domain } = this.$route.query;
      const { mailType } = this.$route.query;
      let items = [...this.pagedData];
      items = items.filter((element) => element !== undefined);

      if (items.length > 0) {
        if (attachmentOnly) items = items.filter((item) => item.attachments.length > 0);

        if (domain) {
          if (mailType === 'Только внешние') {
            items = items.filter(
              (item) => (item.direction === 32 && !item.to.includes(domain))
                || (item.direction === 31 && !item.from.includes(domain)),
            );
          } else if (mailType === 'Только внутренние') {
            items = items.filter(
              (item) => (item.direction === 32 && item.to.includes(domain))
                || (item.direction === 31 && item.from.includes(domain)),
            );
          }
        }

        this.tableData = LogData.filterTable(
          items,
          [
            (item) => dateTime(item.date),
            (item) => dateTime(item.dateSend),
            'subject',
            'from',
            'to',
            'username',
          ],
          this.$route.query.filter,
        );
      }
    },
    onRowClick(id) {
      this.$router.push({
        name: 'computers-computer-log-record',
        params: { record: id },
        query: this.$route.query,
      });
    },
    openCurrentMail() {
      this.openMail(this.rowActiveId);
    },
    openMail(id) {
      const url = getEmailClientsMailLink(
        parseInt(this.$route.params.computer, 10),
        id,
      );

      window.open(url, '_blank');
    },
    getContextmenuButtons(id) {
      return [
        {
          label: 'Открыть письмо',
          onClick: () => this.openMail(id),
        },
      ];
    },
  },
};
</script>

<style lang="scss" module>
.new {
  display: inline-block;
  background: $light-blue;
  color: $blue;
  font-size: 7px;
  padding: 3px;
  margin-right: 8px;
  border-radius: 1px;
}

.dangerous {
  display: inline-block;
  margin-right: 8px;
}

.iframe {
  width: 100%;
  height: 600px;
}
</style>
