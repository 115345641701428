<template>
  <base-collapse>
    <div :class="$style.content">
      <div :class="$style.attachments">
        <div :class="$style.attachmentsTitle">
          Вложенные файлы
        </div>
        <ul v-if="attachments" :class="$style.attachmentsList">
          <li v-for="attachment in attachments" :key="attachment.id">
            <a href="#" @click="saveAttachment(attachment)">
              {{ getTitle(attachment) }}
            </a>
          </li>
        </ul>
        <div v-else>
          Отсутствуют
        </div>
      </div>
      <list :list="list" />
    </div>
  </base-collapse>
</template>

<script>
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import {
  getMessengersAttachment,
  getFileUploadAttachment,
} from '@/api/methods/computers';
import BaseCollapse from '../_base/collapse.vue';
import List from '../_partials/list.vue';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

export default {
  components: {
    BaseCollapse,
    List,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    attachments: {
      type: Array,
      default: () => [],
    },
    log: {
      type: String,
    },
  },
  methods: {
    getTitle(attachment) {
      return attachment.size
        ? `${attachment.filename} (${attachment.size}kB)`
        : attachment.filename;
    },

    async saveAttachment(attachment) {
      let result = {};

      if (this.log === 'messengers') {
        result = await getMessengersAttachment(
          parseInt(attachment.url, 10),
          attachment.id,
        );
      }
      if (this.log === 'file-upload') {
        result = await getFileUploadAttachment(
          parseInt(attachment.url, 10),
          parseInt(attachment.record, 10),
          attachment.id,
        );
      }
      if (!result.success) {
        Vue.$toast.open({
          message: result.message,
          type: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" module>
.content {
  display: flex;
}

.attachments {
  padding: 5px 20px;
  flex-shrink: 0;
  border-right: 1px solid $borderColor;
}

.attachmentsTitle {
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 5px;
}

.attachmentsList {
  font-size: 12px;

  li {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}
</style>
