export class LogData {
  /**
   * @callback FilterCallback
   * @param {Record<string, unknown>} item - строка таблицы
   * @returns {string}
   */
  /**
   *
   * @param {Record<string, unknown>[]} tableData - данные таблицы
   * @param {(string | FilterCallback[]} filters - набор фильтров
   * @param {string} search - строка поиска
   * @returns {Record<string, unknown>[]}
   */
  static filterTable(tableData, filters, search) {
    const searchLowercased = (search || '').toLowerCase();

    if (!filters.length) {
      return tableData;
    }

    return tableData.filter((item) => {
      const filterResults = filters.map((filter) => {
        const itemToCheckIncludes = typeof filter === 'function'
          ? filter(item)
          : `${item[filter]}`;

        return (itemToCheckIncludes || '').toLowerCase().includes(searchLowercased);
      });

      return filterResults.includes(true);
    });
  }
}
